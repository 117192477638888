import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Mother, Tshirt, Material, Breastfeed } from 'components/svg/mother';

export default class HomeTestimonial extends Component {


  render(){

    const lists = [
      {
        name: 'Skin-to-Skin', img: <Mother />, detail: 'Hōyō memungkinkan Bunda menggendong dengan tetap menjaga kontak kulit dengan bayi'
      },
      {
        name: 'Simple', img: <Tshirt />, detail: 'Hōyō berbentuk baju yang dirancang untuk memudahkan proses setup dan menggendong'
      },
      {
        name: 'Premium Quality', img: <Material />, detail: 'Tencel adalah bahan kelas premium yang sifatnya lembut, tahan lama, namun tetap ramah lingkungan'
      },
      {
        name: 'Nursing Wear', img: <Breastfeed />, detail: 'Model baju Hōyō juga memberikan akses bagi bayi untuk menyusui dengan mudah'
      },
    ];

    return (
      <div className="container" style={{marginTop: 90}}>
        <Grid container className="mobile-grid-container" alignItems="center" justify="center" spacing={6}>
          {
            lists.map((x, index) =>
            <Grid item container className="mobile-grid-container" direction="row" alignItems="center" key={index} md={6}>
              <Grid item className="photo-why">
                {x.img}
              </Grid>
              <Grid item xs>
                <div style={{fontSize: 18, fontWeight: 700}}>{x.name}</div>
                <div style={{fontSize: 15, fontWeight: 500, marginTop: 10}}>{x.detail}</div>
              </Grid>
            </Grid>)
          }
        </Grid>
      </div>
    )
  }
}
