import React, {Component} from "react"
import {Switch, withRouter} from 'react-router-dom'
import MyRoute from 'components/general/MyRoute'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Navbar from 'components/general/Navbar';
import {Helmet} from 'react-helmet';
import Home from './pages/Home'
import Logo from "assets/img/logo.png";


class Routing extends Component {

  componentDidMount() {
    // setTimeout(() => {
    //   var preloader = document.querySelector('#preloader-wrapper');
    //   preloader.remove();
    // }, 800);
  }

  render() {
    // console.log(this.props.location);
    // return '';

    var allPages = [...Routing.publicPages];

    var currentPath = this.props.location.pathname;
    var currentPage = Routing.getPage(currentPath, allPages) || { path: '404-not-found', title: 'Halaman Tidak Ditemukan', exact: false, component: Home, public: true, desc: '', tag: '', image: Logo};


    var t_api = typeof Tawk_API !== 'undefined'? Tawk_API: null; // eslint-disable-line no-undef
    if(t_api && typeof t_api.setAttributes !== 'undefined') {
      var val = {location: currentPage.title};
      t_api.setAttributes(val);
    }

    // console.log(currentPage);
    // for detail desc of blog/property in public area will be customed by the page
    var header =
    currentPage.path.includes(':') && currentPage.public ?
    <Helmet>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="356109988164669" />
    </Helmet>
    :
    <Helmet>
      <title>{currentPage.title.includes('Hōyō') ? currentPage.title : 'Hōyō | ' + currentPage.title}</title>
      <meta property="og:title" content={currentPage.title} />
      <meta name="description" property="og:description" content={currentPage.desc} />
      <meta name="keywords" content={currentPage.tag} />
      <meta property="og:image" content={currentPage.image} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="356109988164669" />
      <meta name="author" content="Hōyō" />
    </Helmet>

    return (
      <React.Fragment>
        {
          <Navbar history={this.props.history} />
        }
        { header }
        <TransitionGroup>
          <CSSTransition
            key={currentPath}
            location={currentPath}
            timeout={{ enter: 300, exit: 300 }}
            classNames={'fade'}
            onExit = {function () {
              // this will create smooth transition even when current page is scrolled
              document.getElementsByClassName("fade-exit")[0].style.marginTop = '-' + window.scrollY + 'px';
              window.scrollTo(0, 0);
            }}
            >
            <section className="route-section">
              <Switch location={this.props.location}>
                {
                  allPages.map((p, index) => {
                    return <MyRoute key={index} publicArea={p.public} path={p.path} exact={p.exact} component={p.component} />
                  })
                }
                {/*Page not found at the end*/}
                {/*<Route component={Page404}  />*/}
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>

      </React.Fragment>
    )
  }
}

Routing.publicPages = [
  { path: '/', title: 'Baju Gendong Nyaman untuk Bayi dan Bunda', exact: true, component: Home, public: true, desc: 'Baju Gendong dari bahan berkualitas untuk kenyaman bunda dan bayi', tag: 'baju gendong, babywear, skin to skin', image: Logo},

];

Routing.getPage = (path, pages) => {
  var ret = null;
  for(var i=0; i<pages.length; i++) {
    var page = pages[i];
    if(page.exact) {
      if(path === page.path) {
        ret = page;
        break;
      }
    } else {
      var pathArr = path.split('/');
      var pagePathArr = page.path.split('/');
      var match = true;
      for(var j=0; j<pagePathArr.length; j++) { // make sure all section with no widlcard are same
        if(pagePathArr[j].includes(':'))
        continue;
        else if(pagePathArr[j] !== pathArr[j]) {
          match = false;
          break;
        }
      }
      if (match) {
        ret = page;
        break;
      }
    }
  }
  return ret;
}

export default withRouter(Routing);
