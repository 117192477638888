export function Mother()  {

  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M73.1138 94.6377L85.9627 119.944L86.0047 119.953L106.196 119.996C107.2 119.998 108.014 119.183 108.011 118.18C108.002 114.504 107.96 111.044 107.848 107.853L73.1138 94.6377Z" fill="#CE8624"/>
    <path d="M85.963 119.943L79.0467 103.727C80.8824 103.677 82.8131 103.315 84.6956 102.686C84.912 103.041 85.1481 103.385 85.4053 103.716C87.4486 106.345 90.5795 107.853 93.9953 107.853H107.848C107.804 106.603 107.749 105.395 107.68 104.231L64.8174 85.8821L81.8586 119.943L86.0052 119.952L85.963 119.943Z" fill="#B87923"/>
    <path d="M19.8748 25.0749C16.0278 28.2294 14.4886 32.7225 14.8379 36.5966C15.2368 41.0196 18.5512 46.1707 24.1318 47.0211C28.0365 47.616 33.0222 46.4503 35.0866 44.2618C32.095 36.2684 27.344 24.1277 27.344 24.1277C24.5604 23.8251 22.0367 24.1962 19.8748 25.0749Z" fill="#314A37"/>
    <path d="M24.4874 46.031C18.8927 45.2789 15.7191 41.0396 15.7191 36.5983C15.7191 31.1771 16.8028 28.1795 19.875 25.075C15.0146 27.0505 11.9883 31.5996 11.9883 36.8842C11.9883 43.9678 17.242 49.1887 23.7843 50.1371C28.1484 50.7697 32.5153 49.604 36.2758 47.7987C35.8103 46.5299 35.7183 45.9497 35.0869 44.2622C31.5765 45.7401 28.2955 46.5429 24.4874 46.031Z" fill="#29402F"/>
    <path d="M29.9744 24.1277C29.9744 33.9605 35.4301 39.5809 37.7319 43.863L77.6941 36.3723C77.7223 26.9628 77.7569 14.0825 77.7573 7.14954C72.0611 1.81521 63.2767 0 55.6672 0C50.6054 0 45.4196 0.91509 40.891 2.94592C37.6807 5.38527 29.9744 11.3488 29.9744 24.1277Z" fill="#314A37"/>
    <path d="M44.209 37.6927C43.242 38.63 41.3792 40.2016 39.8032 41.3823C38.9577 42.0158 37.7507 41.792 37.1893 40.8969C34.5476 36.6863 30.9657 32.1203 30.9657 24.1274C30.9657 11.3485 37.6811 5.38491 40.8912 2.94556C33.159 6.413 27.344 13.1354 27.344 24.1274C27.344 35.2659 33.6048 40.5193 36.2754 47.798H77.6576C77.6576 47.798 77.6742 42.9268 77.694 36.372L44.209 37.6927Z" fill="#29402F"/>
    <path d="M76.8233 62.6374C78.137 60.8229 79.2699 58.2626 80.3217 54.9449C80.2867 53.3909 80.7147 52.267 81.3498 51.3743C81.7102 50.0073 82.0647 48.5429 82.4174 46.9807C84.9845 46.8737 87.6221 45.3492 87.6221 42.8655C87.6221 40.1803 83.9434 38.8333 82.8974 35.0432C82.5621 33.8283 82.5123 32.5616 82.7031 31.0325C82.7031 31.0325 82.3909 29.8852 82.5217 29.2492C82.6719 28.5198 83.3876 27.1114 83.3876 27.1114C84.81 17.7224 82.2395 11.347 77.7575 7.14966C77.7568 18.9123 61.8767 18.8034 61.8767 18.8034C61.8767 18.8034 60.4676 25.8636 53.3246 33.1925C52.3288 31.6861 50.672 30.6998 48.7957 30.6998C45.7508 30.6998 43.2827 33.2964 43.2827 36.4994C43.2827 38.5307 43.8479 40.3759 44.8574 41.8753C46.4483 44.2383 49.6818 59.2789 49.6818 59.2789L72.7395 64.0859L76.8233 62.6374Z" fill="#FEDDC5"/>
    <path d="M74.6102 48.598C74.0286 47.7845 72.8974 47.5967 72.0839 48.1781C71.2705 48.7598 71.0824 49.8909 71.664 50.7044C73.621 53.4412 77.3284 54.752 80.3354 54.8935C80.6805 53.8019 81.017 52.629 81.3481 51.3739C78.9737 51.3734 75.8715 50.3622 74.6102 48.598Z" fill="#E0B392"/>
    <path d="M59.6894 76.8922V63.4073C62.6585 64.5737 67.034 65.9698 70.981 65.9698C73.3237 65.9698 75.2099 64.8659 76.8235 62.6371C76.0678 62.6371 72.0581 62.4927 70.981 62.348C61.5109 61.0768 52.5507 57.2032 52.5507 50.3106L52.4973 45.74C49.146 45.74 46.4483 44.2384 44.8574 41.8754C42.9089 43.764 39.8691 46.0732 36.2754 47.7983C36.9992 49.771 37.4616 51.8907 37.4616 54.3184C37.4616 60.7958 35.3835 67.084 33.4048 73.5372L59.6894 76.8922Z" fill="#F5CFB3"/>
    <path d="M96.6298 75.1528C94.456 68.5538 88.2465 63.7881 80.9194 63.7881C77.0268 63.7881 73.4512 65.1361 70.6255 67.3859C68.0826 70.6269 66.2423 76.877 67.9423 80.8631C69.7145 85.0179 73.8124 94.6259 73.8124 94.6259C75.4697 97.5515 80.8214 97.3784 83.305 96.4925C83.9211 93.6664 98.7198 82.6493 98.7198 82.6493C98.722 82.5834 98.7258 82.5185 98.7258 82.4513C98.7258 81.2313 98.2205 80.1486 97.4384 79.4547C97.4384 79.4538 97.4382 79.453 97.4382 79.4521L96.6298 75.1528Z" fill="#FEDDC5"/>
    <path d="M90.3379 77.3863C89.6797 78.1394 89.757 79.2833 90.5103 79.9413C91.2634 80.5992 92.4073 80.5222 93.0653 79.7691C93.6914 79.0525 94.5241 78.6578 95.4098 78.6578C96.173 78.6578 96.8768 78.9545 97.4377 79.4521C97.3595 77.9573 97.0787 76.5168 96.6293 75.1531C96.2331 75.0775 95.826 75.036 95.4098 75.036C93.4912 75.036 91.6427 75.8927 90.3379 77.3863Z" fill="#F5CFB3"/>
    <path d="M88.2958 87.6027C85.6638 89.2115 83.9205 91.7346 83.3043 94.5608C80.8208 95.4467 77.9251 95.7222 76.2263 94.6257C76.2263 94.6257 72.1284 85.0178 70.3562 80.8629C68.6561 76.8769 69.1603 70.6267 70.6249 67.3857C66.8187 70.4164 64.3765 75.0867 64.3765 80.3303V94.4466L72.6045 98.2475C76.8724 101.459 83.1213 100.06 87.2776 97.5195C88.3844 96.843 95.8952 87.2022 95.8952 87.2022C97.6522 86.1282 98.6564 84.6433 98.7189 82.6492C97.0681 82.7387 95.4456 83.232 94.0063 84.1119L88.2958 87.6027Z" fill="#F5CFB3"/>
    <path d="M76.8566 94.6903C76.6772 94.6903 76.5004 94.6814 76.3276 94.6631C75.333 94.5575 74.6123 93.6656 74.7181 92.6711C74.8238 91.6766 75.7165 90.9554 76.71 91.0616C77.3436 91.1282 78.4393 90.7301 78.8811 90.2713C79.5753 89.5511 80.7217 89.5301 81.4415 90.224C82.1617 90.9179 82.1829 92.0643 81.4888 92.7843C80.3803 93.9348 78.5059 94.6903 76.8566 94.6903Z" fill="#E0B392"/>
    <path d="M81.8582 119.943L66.5538 84.0603C63.8317 77.6783 57.5632 73.537 50.6249 73.537H37.0263C37.0263 73.537 31.4158 83.2522 31.4158 91.9841C31.4158 104.297 39.2855 120 39.2855 120H56.9113L58.0727 118.48L60.8488 120L81.8582 119.943Z" fill="#B25416"/>
    <path d="M39.2856 120C35.4309 110.962 33.5128 101.78 33.5128 91.8031C33.5128 85.4003 35.2286 79.4003 37.0264 73.537H33.4047C31.6068 79.4003 29.8911 85.4003 29.8911 91.8031C29.8911 101.363 31.6522 110.193 35.1907 118.865C35.4698 119.549 36.1326 120 36.8714 120H39.2856Z" fill="#9E4B14"/>
    <path d="M60.8485 120H56.911L49.6815 103.05C49.2892 102.13 49.7168 101.066 50.6369 100.674C51.5573 100.282 52.6207 100.709 53.013 101.629L60.8485 120Z" fill="#9E4B14"/>
    <path d="M93.9949 103.024L107.407 100.61C106.985 96.1213 106.281 92.4933 105.118 89.9817C103.497 86.4846 99.1841 85.1919 95.8953 87.2022L90.1848 90.6928C88.3909 91.7895 87.3307 93.3611 86.9011 95.0473C87.6453 98.0546 90.2068 103.024 93.9949 103.024Z" fill="#FEDDC5"/>
    <path d="M93.995 100.609C90.2069 100.609 87.6454 98.0545 86.901 95.0472C85.8234 99.2774 88.7201 104.231 93.995 104.231H107.68C107.606 102.969 107.515 101.762 107.407 100.609H93.995Z" fill="#F5CFB3"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="120" height="120" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export function Tshirt() {
  return (
    <svg width="139" height="139" viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M104.603 45.1758L77.4598 30.9189L78.2863 25.959C82.4885 25.5799 85.7011 22.0466 85.6794 17.8264C85.6565 13.6071 82.4066 10.1075 78.1993 9.77392C73.993 9.44051 70.2323 12.3841 69.5446 16.5475C69.36 17.6554 70.1087 18.7034 71.2166 18.8881C72.3246 19.0727 73.3726 18.324 73.5572 17.2161C73.9264 15.0002 76.0224 13.5029 78.2383 13.8722C80.4543 14.2414 81.9515 16.3374 81.5823 18.5533C81.213 20.7692 79.117 22.2665 76.9011 21.8972C75.7932 21.7126 74.7452 22.4613 74.5606 23.5692L73.4473 30.2503L43.1475 34.9354C42.036 35.1107 41.2773 36.1532 41.4517 37.2636C41.6269 38.3751 42.6694 39.1338 43.78 38.9584L74.8777 34.0407L102.702 48.7704C103.695 49.2964 104.928 48.9158 105.454 47.9221C105.979 46.9273 105.599 45.6961 104.604 45.1699L104.603 45.1758Z" fill="#CE8624"/>
    <path d="M125.387 65.0301L105.993 45.3026L86.9329 35.9407C85.8253 42.5875 79.2038 49.0867 72.5551 47.9788C65.9064 46.8709 61.75 38.5758 62.8576 31.9291L41.7919 34.6048L17.3828 44.9714L27.7494 69.3806L36.9904 63.4205L27.0826 122.881L91.2833 133.579L101.069 74.849L108 82.7527L125.387 65.0301Z" fill="#B25416"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="120" height="120" fill="white" transform="translate(19.7236) rotate(9.46023)"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export function Material() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M59.9512 119.191L0 78.9194L59.9512 38.647L82.8015 53.9999C91.5553 59.8835 102.096 62.0731 112.476 60.1728L120 58.7924L116.501 65.5944C108.47 81.2027 96.5287 94.6213 81.9703 104.395L59.9512 119.191Z" fill="#FEDDC5"/>
    <path d="M59.9512 100.381L0 60.1093L59.9512 19.8369L82.8015 35.1898C91.5553 41.0734 102.096 43.263 112.476 41.3627L120 39.9823L116.501 46.7844C108.47 62.3926 96.5287 75.8112 81.9703 85.5854L59.9512 100.381Z" fill="#CE8624"/>
    <path d="M59.9512 81.5708L0 41.2993L59.9512 1.02686L82.8015 16.3797C91.5553 22.2634 102.096 24.453 112.476 22.5527L120 21.1722L116.501 27.9743C108.47 43.5825 96.5287 57.0012 81.9703 66.7754L59.9512 81.5708Z" fill="#B25416"/>
    <path d="M29.8552 37.0027H37.3792V44.5267H29.8552V37.0027Z" fill="#9C4A14"/>
    <path d="M44.9031 29.4788H52.4271V37.0027H44.9031V29.4788Z" fill="#9C4A14"/>
    <path d="M56.1892 21.9548H63.7132V29.4788H56.1892V21.9548Z" fill="#9C4A14"/>
    <path d="M44.9031 44.5269H52.4271V52.0508H44.9031V44.5269Z" fill="#9C4A14"/>
    <path d="M56.1892 55.8127H63.7132V63.3367H56.1892V55.8127Z" fill="#9C4A14"/>
    <path d="M59.9512 37.0027H67.4752V44.5267H59.9512V37.0027Z" fill="#9C4A14"/>
    <path d="M71.2371 29.4788H78.761V37.0027H71.2371V29.4788Z" fill="#9C4A14"/>
    <path d="M71.2371 44.5269H78.761V52.0508H71.2371V44.5269Z" fill="#9C4A14"/>
    <path d="M93.8091 29.4788H101.333V37.0027H93.8091V29.4788Z" fill="#9C4A14"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="120" height="120" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export function Breastfeed() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M31.3148 20.7531L30.4587 80.8153L87.7744 81.6329L88.6304 21.5697C88.8071 9.19088 78.3143 -0.666602 65.9711 0.280969L61.952 0.589502C60.8286 0.675561 59.6998 0.659082 58.5801 0.540979L54.5719 0.118005C42.2599 -1.18021 31.4905 8.37423 31.3148 20.7531Z" fill="#314A37"/>
    <path d="M65.9711 0.281059L61.952 0.589591C61.2159 0.645438 60.478 0.653678 59.741 0.62255C69.4236 2.55157 76.8055 11.1703 76.6572 21.5698L75.8039 81.4618L87.7744 81.633L88.6304 21.5698C88.8071 9.19097 78.3152 -0.666512 65.9711 0.281059Z" fill="#29402F"/>
    <path d="M94.3872 76.3392C87.6947 72.9417 80.6314 70.5797 73.4216 69.2503C70.6311 68.7358 68.6096 66.2968 68.6096 63.4596V52.2004H50.2066V63.4596C50.2066 66.2977 48.1851 68.7367 45.3937 69.2503C38.1848 70.5797 31.1215 72.9417 24.4281 76.3392C19.6509 78.7645 16.6406 83.6671 16.6406 89.0257L18.0194 118.349C18.0634 119.273 18.8251 120 19.7498 120H101.816C102.805 120 103.593 119.174 103.546 118.186L102.176 89.0257C102.175 83.668 99.1653 78.7645 94.3872 76.3392Z" fill="#FEDDC5"/>
    <path d="M24.4281 76.3392C19.6509 78.7645 16.6406 83.668 16.6406 89.0257L18.0194 118.349C18.0624 119.273 18.8251 120 19.7498 120H66.6348L45.3937 69.2512C38.1848 70.5797 31.1206 72.9417 24.4281 76.3392Z" fill="#B25416"/>
    <path d="M89.5862 92.4809C87.8613 80.8519 77.3108 73.416 72.7213 69.0709C70.3006 68.3082 68.6096 66.0524 68.6096 63.4596V61.0811C65.1425 62.8206 61.702 63.6702 59.3665 63.6866C58.6212 63.6601 57.7634 63.5438 56.8323 63.3406V71.6508C56.8323 74.489 58.8547 76.9279 61.6452 77.4424C68.8541 78.7709 75.9183 81.1329 82.6108 84.5305C85.925 86.2132 88.3878 89.0889 89.5862 92.4809Z" fill="#F5CFB3"/>
    <path d="M96.8802 77.9514C95.7321 77.0248 94.4486 76.3547 93.1284 75.7138C86.8112 72.6505 80.1801 70.4962 73.4216 69.2502C73.266 69.2218 73.1149 69.1852 72.9648 69.1449C72.9245 69.1339 72.8842 69.1229 72.8448 69.111C72.8091 69.101 72.7725 69.0918 72.7368 69.0808C77.3483 73.4836 88.2092 81.0101 89.6713 93.064C90.6665 101.268 68.5757 110.389 61.9519 120H101.816C102.805 120 103.593 119.174 103.546 118.186L102.176 89.0256C102.175 84.6722 100.188 80.6192 96.8802 77.9514Z" fill="#B87923"/>
    <path d="M86.4368 83.5536C81.0444 79.6543 63.675 84.6641 62.0728 94.7615C37.4277 95.0471 29.6128 120 29.6128 120H62.71C62.71 120 74.2548 118.572 80.463 106.738C83.7287 106.141 86.8387 104.178 88.9316 101.284C91.1042 98.2789 91.691 94.6113 90.8515 91.277C90.185 88.6265 88.8245 85.2812 86.4368 83.5536Z" fill="#CE8624"/>
    <path d="M40.0911 103.685C33.1779 110.257 30.1979 118.291 29.6925 119.762C29.6898 119.77 29.6879 119.777 29.6852 119.785C29.6714 119.823 29.6605 119.857 29.6513 119.885C29.6486 119.893 29.6458 119.9 29.644 119.906C29.6357 119.931 29.6293 119.951 29.6248 119.965C29.6238 119.969 29.6211 119.975 29.6202 119.979C29.6165 119.991 29.6138 120 29.6138 120H62.7101C62.7101 120 74.2549 118.572 80.4631 106.738C83.7288 106.141 86.8388 104.178 88.9317 101.284C66.7585 108.737 46.9786 105.31 40.0911 103.685Z" fill="#B87923"/>
    <path d="M62.0728 94.7615C81.7987 97.2965 86.4368 83.5536 86.4368 83.5536C81.0443 79.6543 63.6749 84.6641 62.0728 94.7615Z" fill="#F1C5A5"/>
    <path d="M39.7157 26.5694L36.3703 26.5218C33.5322 26.4815 31.2004 28.7502 31.1601 31.5892L31.1253 34.0199C31.085 36.859 33.3509 39.1936 36.1891 39.2339L39.5344 39.2815L39.7157 26.5694Z" fill="#F5CFB3"/>
    <path d="M80.0675 27.1445L83.4119 27.1921C86.251 27.2324 88.5169 29.567 88.4766 32.4061L88.4418 34.8368C88.4006 37.6758 86.0697 39.9445 83.2316 39.9042L79.8862 39.8566L80.0675 27.1445Z" fill="#F5CFB3"/>
    <path d="M59.1917 14.8598C51.6212 21.6805 42.4604 23.41 39.3476 23.8155C38.7186 23.8979 38.2471 24.4308 38.2407 25.0662L38.0631 42.1114C37.858 56.4752 52.8407 63.4542 59.3665 63.6868C65.897 63.6401 81.0719 57.0913 81.2769 42.7276L81.5846 25.6841C81.5955 25.0488 81.1405 24.5022 80.5134 24.4024C77.4134 23.9089 68.3057 21.9186 60.933 14.8846C60.4469 14.4213 59.6906 14.4103 59.1917 14.8598Z" fill="#FEDDC5"/>
    <path d="M80.5134 24.4025C78.534 24.0875 74.1028 23.1583 69.2386 20.6982L68.9667 42.7277C68.8157 53.3698 60.4468 59.7208 53.4641 62.3172C55.7035 63.176 57.7836 63.6301 59.3665 63.6868C65.897 63.6401 81.0718 57.0914 81.2769 42.7277L81.5845 25.6842C81.5955 25.0488 81.1405 24.5023 80.5134 24.4025Z" fill="#F5CFB3"/>
    <path d="M59.6713 54.6614C59.6127 54.6614 59.555 54.6605 59.4955 54.6596C55.794 54.6074 52.4634 52.8917 50.8026 50.1826C50.2807 49.3303 50.5481 48.2152 51.4004 47.6924C52.2537 47.1696 53.3679 47.437 53.8907 48.2893C54.8932 49.9245 57.1133 51.003 59.5477 51.0378C61.9821 51.067 64.2325 50.0572 65.2807 48.4523C65.8273 47.6146 66.9497 47.3784 67.7865 47.9259C68.6242 48.4724 68.8595 49.5949 68.313 50.4326C66.6037 53.051 63.3041 54.6614 59.6713 54.6614Z" fill="#C35F58"/>
    <path d="M99.6074 80.876C99.7301 81.0518 99.8491 81.2312 99.9645 81.4125C99.8491 81.2312 99.7301 81.0518 99.6074 80.876Z" fill="#EFC79C"/>
    <path d="M99.1479 80.2551C99.2889 80.4355 99.4281 80.6195 99.5609 80.8072C99.4281 80.6195 99.2899 80.4364 99.1479 80.2551Z" fill="#EFC79C"/>
    <path d="M100.031 81.5168C100.15 81.71 100.266 81.9069 100.377 82.1055C100.266 81.9069 100.15 81.71 100.031 81.5168Z" fill="#EFC79C"/>
    <path d="M98.6113 79.6079C98.7642 79.7809 98.9107 79.9576 99.0544 80.138C98.9107 79.9576 98.7642 79.7809 98.6113 79.6079Z" fill="#EFC79C"/>
    <path d="M96.8801 77.9517C97.0651 78.1009 97.2445 78.2556 97.4212 78.4131C97.2445 78.2556 97.0651 78.1009 96.8801 77.9517Z" fill="#EFC79C"/>
    <path d="M98.1133 79.0742C98.2643 79.2289 98.4117 79.3855 98.5555 79.5466C98.4117 79.3864 98.2643 79.2289 98.1133 79.0742Z" fill="#EFC79C"/>
    <path d="M100.445 82.231C100.557 82.437 100.666 82.6448 100.768 82.8563C100.666 82.6448 100.557 82.437 100.445 82.231Z" fill="#EFC79C"/>
    <path d="M98.0264 78.9845C97.8644 78.8224 97.6987 78.665 97.5293 78.5103C97.6987 78.665 97.8644 78.8224 98.0264 78.9845Z" fill="#EFC79C"/>
    <path d="M100.769 82.8572C100.87 83.0687 100.964 83.2829 101.056 83.499C100.964 83.2829 100.87 83.0687 100.769 82.8572Z" fill="#EFC79C"/>
    <path d="M102.087 87.4531C102.113 87.6838 102.132 87.9173 102.146 88.1508C102.132 87.9173 102.113 87.6838 102.087 87.4531Z" fill="#EFC79C"/>
    <path d="M96.8802 77.9517C96.1167 77.3356 95.2836 76.7936 94.3873 76.3395C94.2225 76.2552 94.0558 76.1747 93.8901 76.0923C94.9402 76.6251 95.9547 77.2047 96.8802 77.9517Z" fill="#EFC79C"/>
    <path d="M102.175 89.0259C102.175 88.7824 102.166 88.5398 102.155 88.2981C102.166 88.5398 102.175 88.7824 102.175 89.0259Z" fill="#EFC79C"/>
    <path d="M102.978 106.089L102.175 89.0259L102.978 106.089Z" fill="#EFC79C"/>
    <path d="M101.979 86.6768C102.014 86.8919 102.045 87.1098 102.071 87.3277C102.046 87.1098 102.014 86.8919 101.979 86.6768Z" fill="#EFC79C"/>
    <path d="M101.112 83.6316C101.198 83.8431 101.28 84.0564 101.356 84.2715C101.28 84.0564 101.198 83.8431 101.112 83.6316Z" fill="#EFC79C"/>
    <path d="M101.828 85.9077C101.879 86.1348 101.926 86.3636 101.965 86.5934C101.926 86.3636 101.879 86.1348 101.828 85.9077Z" fill="#EFC79C"/>
    <path d="M101.396 84.3887C101.467 84.5938 101.534 84.7988 101.594 85.0067C101.533 84.7997 101.467 84.5938 101.396 84.3887Z" fill="#EFC79C"/>
    <path d="M101.619 85.0874C101.683 85.3099 101.741 85.5351 101.794 85.7612C101.741 85.5351 101.683 85.3099 101.619 85.0874Z" fill="#EFC79C"/>
    <path d="M89.9432 93.652C89.9359 93.6264 89.9294 93.5999 89.9221 93.5742C89.9294 93.5999 89.9359 93.6264 89.9432 93.652Z" fill="#EFC79C"/>
    <path d="M102.857 119.65C102.792 119.699 102.724 119.744 102.652 119.784C102.724 119.744 102.792 119.699 102.857 119.65Z" fill="#EFC79C"/>
    <path d="M103.489 118.72C103.509 118.645 103.522 118.567 103.532 118.49C103.522 118.567 103.509 118.645 103.489 118.72Z" fill="#EFC79C"/>
    <path d="M103.539 118.429C103.546 118.349 103.55 118.269 103.546 118.187C103.55 118.269 103.546 118.349 103.539 118.429Z" fill="#EFC79C"/>
    <path d="M102.626 119.797C102.558 119.833 102.488 119.864 102.414 119.891C102.488 119.864 102.558 119.833 102.626 119.797Z" fill="#EFC79C"/>
    <path d="M103.263 119.217C103.308 119.15 103.346 119.079 103.381 119.005C103.347 119.079 103.308 119.15 103.263 119.217Z" fill="#EFC79C"/>
    <path d="M103.096 119.431C103.148 119.374 103.194 119.315 103.238 119.252C103.194 119.315 103.148 119.374 103.096 119.431Z" fill="#EFC79C"/>
    <path d="M103.402 118.959C103.435 118.882 103.466 118.804 103.487 118.721C103.466 118.803 103.435 118.882 103.402 118.959Z" fill="#EFC79C"/>
    <path d="M102.898 119.617C102.961 119.567 103.021 119.513 103.077 119.453C103.022 119.513 102.961 119.567 102.898 119.617Z" fill="#EFC79C"/>
    <path d="M90.0751 94.2059C90.0751 94.2041 90.0751 94.2023 90.0742 94.2004C90.0751 94.2023 90.0751 94.2041 90.0751 94.2059Z" fill="#EFC79C"/>
    <path d="M90.3514 96.0802C90.3459 96.0198 90.3405 95.9594 90.335 95.8989C90.3405 95.9594 90.3459 96.0198 90.3514 96.0802Z" fill="#EFC79C"/>
    <path d="M90.2913 95.4913C90.2849 95.441 90.2794 95.3897 90.2729 95.3394C90.2794 95.3897 90.2849 95.441 90.2913 95.4913Z" fill="#EFC79C"/>
    <path d="M90.3864 96.6512C90.3836 96.5945 90.3799 96.5377 90.3772 96.481C90.3799 96.5377 90.3836 96.5945 90.3864 96.6512Z" fill="#EFC79C"/>
    <path d="M90.1922 94.8103C90.1913 94.8048 90.1904 94.7984 90.1895 94.792C90.1904 94.7984 90.1913 94.8048 90.1922 94.8103Z" fill="#EFC79C"/>
    <path d="M89.762 93.0159C89.7693 93.0397 89.7766 93.0644 89.7839 93.0891C89.7766 93.0644 89.7693 93.0397 89.762 93.0159Z" fill="#EFC79C"/>
    <path d="M101.816 120C101.905 120 101.993 119.991 102.079 119.978C101.993 119.991 101.905 120 101.816 120Z" fill="#EFC79C"/>
    <path d="M102.132 119.969C102.214 119.954 102.295 119.934 102.372 119.907C102.295 119.934 102.214 119.954 102.132 119.969Z" fill="#EFC79C"/>
    <path d="M81.2557 106.089H72.9217C65.2285 106.089 58.9901 112.313 58.9626 120H81.2557V106.089Z" fill="#FEDDC5"/>
    <path d="M102.978 106.089L102.175 89.0256C102.175 84.6722 100.188 80.6192 96.8802 77.9514C95.7321 77.0248 94.4485 76.3547 93.1284 75.7138C86.8112 72.6505 80.1801 70.4962 73.4216 69.2502C73.266 69.2218 73.1149 69.1852 72.9648 69.1449C72.9245 69.1339 72.8842 69.1229 72.8448 69.111C72.8091 69.101 72.7725 69.0918 72.7368 69.0808C75.9686 72.1661 82.2629 76.7886 86.2454 83.4253C86.3095 83.4674 86.3754 83.5095 86.4368 83.5535C88.8245 85.2802 90.1849 88.6264 90.8514 91.2769C91.4392 93.6133 91.3229 96.1118 90.4559 98.4326L90.6619 102.821C90.7452 104.601 89.3253 106.089 87.5427 106.089H81.2558V120H101.815C102.619 120 103.29 119.454 103.488 118.72C103.533 118.55 103.554 118.371 103.545 118.186L102.978 106.089Z" fill="#B25416"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="120" height="120" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  )
}
