import cf from 'currency-formatter';
import { setMessage, setCloseMessage } from 'redux/actions'
import { animateScroll as scroll } from 'react-scroll';

export function price_d2r(str, shortStyle)
{
  let _shortStyle = typeof shortStyle === 'undefined' ? false : shortStyle;
  str = typeof str === 'undefined' || str === null ? '' : str;
  str = typeof str !== "string"? str.toString(): str;
  str = str.trim();
  var isMinus = str.length > 0 && str.charAt(0) === '-';
  str = str && typeof str === "string" ? str.replace(/\D/g,'') : '';

  if(!_shortStyle) {
    return (isMinus? '-' : '' ) + cf.format(str, {
      symbol: 'Rp',
      decimal: ',',
      thousand: '.',
      precision: 0,
      format: '%s %v' // %s is the symbol and %v is the value
    });
  } else {
    let formatted = '';
    let number = parseInt(str);
    console.log(number);
    if (number <= 10000) {  // less than 10 thousands, show as is
      formatted = number.toLocaleString('id', {minimumFractionDigits: 0, maximumFractionDigits: 0});
    } else if (number > 10000 && number < 1000000) { // 10 rb - 1 jt => 124 rb
      formatted = parseInt(number / 1000) + ' rb';
    } else if (number >= 1000000 && number < 10000000) { // 1 - 10 jt -> e.g. 5,84 jt
      formatted = (number / 1000000).toLocaleString('id', {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' jt';
    } else if (number >= 10000000 && number < 1000000000) { // 10 jt- 1 M -> e.g. 432 jt
      formatted = (number / 1000000).toLocaleString('id', {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' jt';
    } else if (number >= 1000000000 && number < 10000000000) { // 1 - 10 M -> e.g. 3.54 M
      formatted = (number / 1000000000).toLocaleString('id', {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' M';
    } else if (number >= 10000000000 && number < 100000000000) { // 10 - 100 M -> e.g. 35.4 M
      formatted = (number / 1000000000).toLocaleString('id', {minimumFractionDigits: 0, maximumFractionDigits: 1}) + ' M';
    } else if (number >= 100000000000 && number < 1000000000000) { // 100 M - 1 T -> e.g. 354 M
      formatted = (number / 1000000000).toLocaleString('id', {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' M';
    } else { // > 1 T -> 5,34 T
      formatted = (number / 1000000000000).toLocaleString('id', {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' T';
    }

    return 'Rp ' + formatted;
  }
}

export function price_r2d(str)
{
  if(str && typeof str === "string")
  return str.replace(/\D/g,'') === ''? 0 : parseInt(str.replace(/\D/g,''));
  else
  return 0;
}

export function array_length(str){
  if(str && typeof str === "string"){
    var del_percent =  str.replace(/%/g,"");
    var str_trim = del_percent.trim();
    return str_trim.split("-").length;
  }
}

export function get_range(str, param)
{
  if(str && typeof str === "string"){
    var del_percent =  str.replace(/%/g,"");
    var str_trim = del_percent.trim();
    var str_splitted = str_trim.split("-");
    if (str_splitted.length>0){
      if(param==="min"){
        return str_splitted[0]
      } else if(param==="max"){
        return str_splitted[1]
      }
    }
    else if (str_splitted.length === 0 && param === null ){
      return parseInt(str_splitted[0])
    }
  }
  else{
    return str;
  }
}

export function date_r2d(date, date_js) {

  var d = (date_js === undefined) ? new Date(date) : date_js;
  if (isNaN(d.getMonth()))
  return '';

  var month = '' + (d.getMonth() + 1);
  var day = '' + d.getDate();
  var year = d.getFullYear();

  if (month.length < 2)
  month = '0' + month;
  if (day.length < 2)
  day = '0' + day;

  return [year, month, day].join('-');
}

export function date_d2r(date, date_js) {

  var d = (date_js === undefined) ? new Date(date) : date_js;
  if (isNaN(d.getMonth()))
  return '';

  var m_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  var curr_date = d.getDate();
  var curr_month = d.getMonth();
  var curr_year = d.getFullYear();
  return  String("00" + curr_date).slice(-2) + " " + m_names[curr_month] + " " + curr_year;
}

// data is the string to be filtered
// keywords is the array of keyword
export function formatter_hilite (data, keywords) {
  data = String(data);
  for (let i = 0; i < keywords.length; i++) {
    data = data.replace(new RegExp(keywords[i] + '+(?![^<]*>)', 'gi'), function (str) {
      return "<myopen>" + str + "<myclose>";
    });
  }
  data = data.replace(new RegExp("<myopen>", 'gi'), '<span class="hilite">');
  data = data.replace(new RegExp("<myclose>", 'gi'), '</span>');

  return data;
}
export function debounceFn(func, wait, immediate) {
  var timeout;

  // Calling debounce returns a new anonymous function
  return function() {
    // reference the context and args for the setTimeout function
    var context = this,
    args = arguments;

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    // Set the new timeout
    timeout = setTimeout(function() {

      timeout = null;
      if (!immediate) {
        // Call the original function with apply
        // apply lets you define the 'this' object as well as the arguments
        //    (both captured before setTimeout)
        func.apply(context, args);
      }
    }, wait);

    // Immediate mode and no wait timer? Execute the function..
    if (callNow) func.apply(context, args);
  };
}

// name: 'fieldName',
// type: 'text' (number,date, default:text),
// label: 'Full Name' (default: '')
// valid: 'required' (<empty>, requiredImg default: required, can be array),
// validParam: <anything> (default: '')
// default: '' (default:'') }
// should be called in constructor return objects to be put in state
export function formDef(obj, fields) {
  obj.fieldDefs = fields;

  var initState = {};
  for(var i=0; i<fields.length; i++){ // set default And prepare state
    var f = fields[i];
    f.type = typeof f.type === 'undefined' ? 'text': f.type;
    f.label = typeof f.label === 'undefined' ? '': f.label;
    f.valid = typeof f.valid === 'undefined' ? 'required': f.valid;
    f.validParam = typeof f.validParam === 'undefined' ? '': f.validParam;
    f.default = typeof f.default === 'undefined' ? '': f.default;

    initState[f.name] = typeof f.default === 'undefined' ? '': f.default;
  }
  return initState;
}
// return null if not found
export function formGetLabel(obj, name) {

  if(typeof obj.fieldDefs === 'undefined')
  return null;

  var fields = obj.fieldDefs;
  for (var i=0; i<fields.length; i++) {
    var f = fields[i];
    if(fields[i].name === name)
    return f.label;
  }
  return null;
}
export function formIsRequired(obj, name) {
  if(typeof obj.fieldDefs === 'undefined')
  return false;

  var fields = obj.fieldDefs;
  for (var i=0; i<fields.length; i++) {
    var f = fields[i];
    if(fields[i].name === name) {
      if(Array.isArray(f.valid)) {
        for(var j=0; j<f.valid.length; j++) {
          if(f.valid[j].includes('required'))
          return true;
        }
      } else {
        return f.valid.includes('required');
      }
    }
  }
  return false;
}


export function formValid(obj) {
  var fields = obj.fieldDefs;

  for(var i=0; i<fields.length; i++) {
    var f = fields[i];
    formVal(obj, f.name, f.label, f.valid, f.validParam);
  }
}
// form validation
// obj will be used prop named "errors", make sure to clear it first
// rules can be array or single rule
// here we ASSUME the component use controlled, where all the values are defined state
// return if validation success, can be used for staging validation
export function formVal(obj, field, label, rules, param) {
  var ret = true;
  if(Array.isArray(rules)) {
    for(let i=0; i< rules.length; i++) {
      var _ret = formVal(obj, field, label, rules[i], param);
      ret = _ret === false? false : ret; // return false if any false
    }
    return ret;
  }

  if(rules === 'required') {
    // check against '0' as well
    if(!obj.state[field]) {
      obj.errors[field] = label + ' tidak boleh kosong';
      ret = false;
    }
  }
  if(rules === 'requiredNumber') {
    if(!obj.state[field] || obj.state[field] === '0') {
      obj.errors[field] = label + ' tidak boleh kosong';
      ret = false;
    } else if(parseInt(obj.state[field]) < 0) {
      obj.errors[field] = label + ' tidak boleh negatif';
      ret = false;
    }
  }
  if(rules === 'requiredImg') {
    if(!obj.state[field] && !obj.state[param]) {
      obj.errors[field] = label + ' tidak boleh kosong';
      ret = false;
    }
  }
  if(rules === 'requiredDate') {
    if(!obj.state[field] || obj.state[field] === '0' || obj.state[field] === '0000-00-00') {
      obj.errors[field] = label + ' tidak boleh kosong';
      ret = false;
    }
  }
  if(rules === 'requiredPrice') {
    if(!obj.state[field] || obj.state[field] === 'Rp 0') {
      obj.errors[field] = label + ' harus diisi';
      ret = false;
    }
  }
  if(rules.match(/requiredMinPrice\[\d*\]/)) {
    if(obj.state[field]) { // if not filled, then no need to check
      let _minval = parseInt(rules.match(/\d{1,}/)[0]);
      if(price_r2d(obj.state[field]) < _minval) {
        obj.errors[field] = 'Nilai minimum adalah ' + price_d2r(_minval);
        ret = false;
      }
    }
  }
  // format requiredMin|x where x is the number for character
  if(rules.includes("requiredMin")) {
    if(obj.state[field]) {
      var tmp = rules.split('|');
      if(obj.state[field].length < parseInt(tmp[1])) {
        obj.errors[field] = 'Nilai '+ label + ' harus lebih dari ' + tmp[1] + ' karakter.';
        ret = false;
      }
    }
  }
  if(rules === 'validEmail') {
    // if empty then just leave it
    if(!obj.state[field] || obj.state[field] === '') {
    } else {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(String(obj.state[field]).toLowerCase())) {
        obj.errors[field] = 'Format email tidak valid';
        ret = false;
      }
    }
  }

  return ret;
}

// here we assume all the errors wtll have our class: input-error
// elTag can be id, name, or class, if tag not found then just scroll to 0
export function formScrollToError(elTag) {
  var errors = document.getElementsByClassName('input-error');
  if(errors.length) {
    var location = 0;
    if (elTag) {
      var el = document.querySelector(elTag);
      if(el)
      location = el.getBoundingClientRect().top + window.scrollY - 150;
    } else {
      location = errors[0].getBoundingClientRect().top + window.scrollY - 150;
    }
    scroll.scrollTo(location);
  }
}

// data can be string (form_name), or object (keyvals)
export function formData(data) {
  if(typeof data === 'string') {
    return new FormData(document.getElementById(data));
  } else {
    var ret = (data instanceof FormData) ? data : new FormData();
    Object.entries(data).forEach(entry => {
      let key = entry[0];
      let value = entry[1];
      ret.append(key, value);
    });

    return ret;
  }
}

export function escapeHTML (str) {
  var map =
  {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };
  return str.replace(/[&<>"']/g, function(m) {return map[m];});
}

export function imgPath(path) {
  if (typeof path !== 'string' || path === '')
  return '';

  var be_base_url = ''; // backend base url

  if(window.location.hostname === 'babyhoyo.com') {
    be_base_url = 'https://api.provesty.com/';
  } else if(window.location.hostname === 'localhost') {

    // for development we allowed setting the backend manually thru environment (no need to change the code below)
    if(typeof(process.env.REACT_APP_BACKEND) != 'undefined')
    be_base_url = process.env.REACT_APP_BACKEND;
    else
    be_base_url = 'https://test.provesty.com/';
  } else {
    be_base_url = 'https://test.provesty.com/'; // the case of IP address etc
  }

  // if already absolute path then just return it
  return path.startsWith('http')? path : be_base_url + path;
}

export function dispatchMsg(msgType, msg) {
  window.store.dispatch(setMessage(msgType, msg));
}
// rarely usem will close the message before timeout
export function dispatchCloseMsg() {
  window.store.dispatch(setCloseMessage());
}
