import axios from 'axios';
import { userHelper, imgPath } from 'helpers';


const GET = 'get';
const POST = 'post';


// available resource
function urlMap (resource, urlParams) {
  switch (resource) {
    // userOnly api
    case 'login': return {url: 'api/login', method: POST, authorizedUser: false};
    case 'register': return {url: 'api/register', method: POST, authorizedUser: false};
    case 'login_fb': return {url: 'api/login_fb', method: POST, authorizedUser: false};
    case 'login_google': return {url: 'api/login_google', method: POST, authorizedUser: false};

    case 'hoyo_get': return {url: 'api/hoyo', method: GET, authorizedUser: false};
    case 'hoyo_post': return {url: 'api/hoyo', method: POST, authorizedUser: false};

    case 'listing': return {url: 'api/property', method: POST, authorizedUser: false};
    case 'property': return {url: 'api/property/' + urlParams[0], method: GET, authorizedUser: false};
    case 'blog': return {url: 'api/blog', method: POST, authorizedUser: false};
    case 'blog_detail': return {url: 'api/blog_detail/' + urlParams[0], method: GET, authorizedUser: false};
    case 'blog_hilites': return {url: 'api/blog_hilites', method: POST, authorizedUser: false};
    case 'faq': return {url: 'api/faq', method: POST, authorizedUser: false};
    case 'send_password_reset': return {url: 'api/send_password_reset/', method: POST, authorizedUser: false};
    case 'is_reset_data_valid': return {url: 'api/is_reset_data_valid', method: GET, authorizedUser: false};
    case 'statistic': return {url: 'api/statistic', method: GET, authorizedUser: false};
    case 'reset_password': return {url: 'api/reset_password/', method: POST, authorizedUser: false};
    case 'prohouse_post': return {url: 'api/prohouse/', method: POST, authorizedUser: false};
    case 'sprohouse_post': return {url: 'sepi/prohouse/', method: POST, authorizedUser: true};
    case 'prohouse_get': return {url: 'api/prohouse/', method: GET, authorizedUser: false};
    case 'shipping_fee': return {url: 'api/shipping_fee/', method: GET, authorizedUser: false};

    // private api
    case 'slisting': return {url: 'sepi/property', method: POST, authorizedUser: true};
    case 'dashboard': return {url: 'sepi/dashboard', method: GET, authorizedUser: true};
    case 'withdrawal_get': return {url: 'sepi/withdrawal', method: GET, authorizedUser: true};
    case 'withdrawal_post': return {url: 'sepi/withdrawal', method: POST, authorizedUser: true};
    case 'topup_get': return {url: 'sepi/topup', method: GET, authorizedUser: true};
    case 'topup_post': return {url: 'sepi/topup', method: POST, authorizedUser: true};
    case 'portfolio': return {url: 'sepi/portfolio', method: POST, authorizedUser: true};
    case 'transaction': return {url: 'sepi/transaction', method: POST, authorizedUser: true};
    case 'message': return {url: 'sepi/message', method: POST, authorizedUser: true};
    case 'message_view': return {url: 'sepi/message_view', method: POST, authorizedUser: true};
    case 'message_all_read': return {url: 'sepi/message_all_read', method: POST, authorizedUser: true};
    case 'invest': return {url: 'sepi/invest/' + urlParams[0], method: POST, authorizedUser: true};
    case 'payment': return {url: 'sepi/payment/' + urlParams[0], method: GET, authorizedUser: true};
    case 'mou': return {url: 'sepi/mou/' + urlParams[0], method: GET, authorizedUser: true};
    case 'payment_topup': return {url: 'sepi/payment_topup/' + urlParams[0], method: GET, authorizedUser: true};
    case 'send_sms': return {url: 'sepi/send_sms/', method: POST, authorizedUser: true};
    case 'verify_phone': return {url: 'sepi/verify_phone/', method: POST, authorizedUser: true};
    case 'regencies_select': return {url: 'api/regencies_select/'+ urlParams[0], method: GET, authorizedUser: false};
    case 'districts_select': return {url: 'api/districts_select/'+ urlParams[0], method: GET, authorizedUser: false};
    case 'villages_select': return {url: 'api/villages_select/'+ urlParams[0], method: GET, authorizedUser: false};
    case 'credential_profile_get': return {url: 'sepi/credential_profile/', method: GET, authorizedUser: true};
    case 'credential_document_get': return {url: 'sepi/credential_document/', method: GET, authorizedUser: true};
    case 'credential_profile_post': return {url: 'sepi/credential_profile/', method: POST, authorizedUser: true};
    case 'credential_document_post': return {url: 'sepi/credential_document/', method: POST, authorizedUser: true};
    case 'individual_profile_get': return {url: 'sepi/individual_profile/', method: GET, authorizedUser: true};
    case 'individual_profile_post': return {url: 'sepi/individual_profile/', method: POST, authorizedUser: true};
    case 'individual_experience_get': return {url: 'sepi/individual_experience/', method: GET, authorizedUser: true};
    case 'individual_experience_post': return {url: 'sepi/individual_experience/', method: POST, authorizedUser: true};
    case 'individual_document_get': return {url: 'sepi/individual_document/', method: GET, authorizedUser: true};
    case 'individual_document_post': return {url: 'sepi/individual_document/', method: POST, authorizedUser: true};

    case 'account': return {url: 'sepi/account/', method: GET, authorizedUser: true};
    case 'change_password': return {url: 'sepi/change_password/', method: POST, authorizedUser: true};

    case 'transact_get': return {url: 'sepi/transact/'+ urlParams[0], method: GET, authorizedUser: true};

    case 'company_profile_get': return {url: 'sepi/company_profile/'+ urlParams[0], method: GET, authorizedUser: true};
    case 'company_profile_post': return {url: 'sepi/company_profile/'+ urlParams[0], method: POST, authorizedUser: true};
    case 'company_experience_get': return {url: 'sepi/company_experience/'+ urlParams[0], method: GET, authorizedUser: true};
    case 'company_experience_post': return {url: 'sepi/company_experience/'+ urlParams[0], method: POST, authorizedUser: true};
    case 'company_document_get': return {url: 'sepi/company_document/'+ urlParams[0], method: GET, authorizedUser: true};
    case 'company_document_post': return {url: 'sepi/company_document/'+ urlParams[0], method: POST, authorizedUser: true};

    case 'connect_fb': return {url: 'sepi/connect_fb', method: POST, authorizedUser: true};
    case 'connect_google': return {url: 'sepi/connect_google', method: POST, authorizedUser: true};

    case 'project_info_get': return {url: 'sepi/project_info/'+ urlParams[0], method: GET, authorizedUser: true};
    case 'project_info_post': return {url: 'sepi/project_info/'+ urlParams[0], method: POST, authorizedUser: true};
    case 'project_detail_get': return {url: 'sepi/project_detail/'+ urlParams[0], method: GET, authorizedUser: true};
    case 'project_detail_post': return {url: 'sepi/project_detail/'+ urlParams[0], method: POST, authorizedUser: true};
    case 'project_document_get': return {url: 'sepi/project_document/'+ urlParams[0], method: GET, authorizedUser: true};
    case 'project_document_post': return {url: 'sepi/project_document/'+ urlParams[0], method: POST, authorizedUser: true};
    case 'profile_post': return {url: 'sepi/profile/', method: POST, authorizedUser: true};
    case 'profile_get': return {url: 'sepi/profile/', method: GET, authorizedUser: true};
    case 'sponsor': return {url: 'sepi/sponsor/', method: GET, authorizedUser: true};
    case 'property_select': return {url: 'sepi/property_select/', method: GET, authorizedUser: true};
    case 'confirm_payment': return {url: 'sepi/confirm_payment/', method: POST, authorizedUser: true};
    case 'upload_pot': return {url: 'sepi/upload_pot/', method: POST, authorizedUser: true};
    case 'send_email_verify': return {url: 'sepi/send_email_verify/', method: POST, authorizedUser: true};
    // this is for case where verification code has already set, but user want to change the destination
    case 'change_email': return {url: 'sepi/change_email/', method: POST, authorizedUser: true};
    // this is for case where user already has verified email, but want to change it to other
    case 'change_verified_email': return {url: 'sepi/change_verified_email/', method: POST, authorizedUser: true};

    case 'email_unsub': return {url: 'sepi/email_unsub/', method: POST, authorizedUser: true};

    case 'verify_email': return {url: 'api/verify_email', method: POST, authorizedUser: false};

    case 'rewind': return {url:'sepi/rewind', method: GET, authorizedUser: true};

    // the secured version of property
    case 'sproperty': return {url: 'sepi/property/' + urlParams[0], method: GET, authorizedUser: true};
    case 'appraise': return {url: 'sepi/appraise/', method: POST, authorizedUser: true};

    default:
    return {url: '', method: GET};
  }
}


export const apiHelper = {
  // callerObj the caller object to be used for attaching the cancel source
  // urlParams is the array value used in url, e.g. id of resource
  // fnSuccess takes 1 param -> data object
  // fnError takes one object -> error object (to display message use err.message)
  // headers is the object of keyvals
  // data must be in the form of FormData
  request: function (callerObj, {resource = '', urlParams = [], data = '', headers = {}, fnSuccess = () => {}, fnError = () => {}, fnFinally = () => {}} = {}) {

    // callerObj is mandatory
    if(!callerObj) {
      fnError(new Error('callerObj is required for cancelling purpose'));
      return;
    } else {
      if(typeof callerObj.cancelTokens === 'undefined')
      callerObj.cancelSources = [];
    }

    var {url, method, authorizedUser} = urlMap(resource, urlParams);

    if(authorizedUser) {
      if(!userHelper.isLogin()) {
        fnError(new Error('Login is required for this call'));
        return;
      }
      headers['Authorization'] = userHelper.getToken();
    }

    // get the token here
    const source = axios.CancelToken.source();
    callerObj.cancelSources.push(source);

    url = imgPath(url);

    if(method === GET ) {
      var inputGets = [];
      if(data) {
        for (var pair of data.entries())
        inputGets.push(pair[0]+ '=' + pair[1]);
      }
      if(inputGets.length !== 0)
      url += '?' + inputGets.join('&');
    }

    axios({
      url, method, data, headers,
      responseType: 'json',
      cancelToken: source.token
    })
    .then(rawResponse => rawResponse.data)
    .then(resp => {

      fnSuccess(resp)

    })
    .catch(err => {
      if (!axios.isCancel(err))
      fnError(err);
      else
      return err;
    })
    .then((err) => {
      if (!axios.isCancel(err))
      fnFinally();

      // remove the cancel element
      let index = callerObj.cancelSources.indexOf(source);
      if (index > -1)
      callerObj.cancelSources.splice(index, 1);
    });

  },
  cancelAllRequest: function (callerObj) {
    if(typeof callerObj.cancelSources !== 'undefined') {
      callerObj.cancelSources.forEach((s) => {s.cancel()});
    }

  }
}

export default apiHelper;
