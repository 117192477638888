import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import SocialMedia from './SocialMedia';
import Logo_white from "assets/img/logo-white-small.png";
import { Link } from "react-router-dom";
import ModalStory from 'components/home/ModalStory';
import ModalYoutube from 'components/home/ModalYoutube';
import {Link as Scroller} from 'react-scroll'

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalYoutubeVisible: false,
      modalStoryVisible: false
    };
  }

  render(){

    const wa_link = "https://api.whatsapp.com/send?phone=6285722202275&text=halo!%20saya%20tertarik%20untuk%20tau%20lebih%20jauh%20tentang%20baju%20gendongan%20h%C5%8Dy%C5%8D%20%E2%98%BA%EF%B8%8F";

    const urls = {
      'Produk': {
        'Tentang Hōyō': {type: 'anchor', url: 'tentang'},
        'Cerita Dibalik Hōyō':  {type: 'modal', url: 'modalStoryVisible'},
        'Cara Penggunaan': {type: 'modal', url: 'modalYoutubeVisible'},
        'Keunggulan Hōyō': {type: 'anchor', url: 'why'}
      },
      'Informasi': {
        'Panduan Ukuran': {},
        'Kontak Kami': {type: 'url', url: wa_link}
      },
      'Partner': {
        'Tokopedia': {type: 'url', url: 'https://www.tokopedia.com/'},
        'Shopee': {type: 'url', url: 'https://shopee.co.id/'}
      },
    };

    return(
      <div className="footer" style={{backgroundColor: '#314A37', color: '#F5F4E9'}}>
        <div className="container footer-content" style={{paddingTop: 50}}>

          <Grid container>

            <Grid item lg={5} md={5} sm={12} xs={12} style={{textAlign: 'center'}}>

              {/*<SocialMedia />*/}

              <img src={Logo_white} alt="logo" style={{width: 200}}></img>
            </Grid>
            {
              Object.keys(urls).map((title, index)  => {
                return (
                  <Grid key={index} item xs={6} sm={4} md={2} lg={2} className="important-links">
                    <div className="link-section">{ title }</div>
                    {
                      Object.keys(urls[title]).map((subtitle, index2) => {
                        let ret = '';
                        if (typeof urls[title][subtitle]["type"] === 'undefined') {
                          ret = <div>{ subtitle }
                          </div>;
                        } else if(urls[title][subtitle]["type"] === 'url') {
                          ret =  <a href={urls[title][subtitle]["url"]} rel="noreferrer" target="_blank">
                            { subtitle }
                          </a>;
                        } else if(urls[title][subtitle]["type"] === 'anchor') {
                          ret = <Scroller to={urls[title][subtitle]["url"]} smooth={true} duration={800}>
                            { subtitle }
                          </Scroller>;
                        } else {
                          ret = <Link to="" onClick={() => this.setState({[urls[title][subtitle]["url"]]: true})}>{ subtitle }
                          </Link>;
                        }
                        return (
                          <div key={index2} className="links-footer">
                            {ret}
                          </div>
                        )
                      })
                    }
                    { index === 2 && <div style={{marginTop: 30}}><SocialMedia /></div>}
                  </Grid>
                );
              })
            }
          </Grid>
        </div>

        <div style={{width: '100%', textAlign: 'center', paddingTop: 40, paddingBottom: 10}}>
          <hr/>
          <div className="container">
            <p style={{ fontWeight: 300, fontSize: 14}}>Copyright © 2020 Hoyo Babywearing</p>
          </div>
        </div>

        <ModalYoutube key={Math.random()} visible={this.state.modalYoutubeVisible} onClose={() => this.setState({modalYoutubeVisible: false})} />
        <ModalStory key={Math.random()} visible={this.state.modalStoryVisible} onClose={() => this.setState({modalStoryVisible: false})} />
      </div>
    )
  }
}

export default Footer;
