import React from 'react';
import Rodal from 'rodal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

export default class ModalYoutube extends React.Component {

  componentDidUpdate() {
    // responsive height
    // const height = this.divElement.clientHeight; // 378
    // const { modalHeight } = this.state; // 370
    // var diff = 1;
    // if(height > diff && modalHeight !== height - diff)
    // this.setState({modalHeight: (height - diff)});
  }

  render() {

    const { visible, onClose } = this.props;

    return (

      <Rodal className="youtube-modal" visible={visible} onClose={onClose}
        showCloseButton={false} width={800} height={450}
        >
        <Button onClick={onClose} className="rodal-btn-close"><CloseIcon /></Button>
        <iframe title="Instruksi Pemakaian Hōyō" style={{borderRadius: 10, maxWidth: '100%'}} width="800" height="450" src="https://www.youtube.com/embed/a_3kwNLP8qo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Rodal>
    );
  }
}
