import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ModalYoutube from 'components/home/ModalYoutube'
import 'rodal/lib/rodal.css';
import {Link as Scroller} from 'react-scroll'

export default class HomeBanner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  render(){

    return (
      <div id="bg-banner">

        <div className="container" id="home-banner-container">
          <Grid container direction="column">
            <div id="banner-title">
              Menggendong dengan Ergonomis dan Tetap Stylish
            </div>
            <div id="banner-subtitle" className="fontsize-medbig color-light" style={{marginTop: 20}}>
              Hōyō adalah sebuah produk baju gendongan pertama di Indonesia
            </div>

            <Grid container spacing={2} id="home-button-group">
              <Grid item xs={12} sm="auto">
                <Scroller to="order" smooth={true} duration={800}>
                  <Button className="button-orange fontsize-big" style={{padding: "13px 33px"}}>Beli Sekarang</Button>
                </Scroller>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button className="button-orange-trans fontsize-big" style={{padding: "13px 16px"}} onClick={() => this.setState({modalVisible: true})}>Cara Penggunaan <PlayCircleFilledIcon style={{marginLeft: 15, color: "#B25416", fontSize: 30}} /></Button>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <ModalYoutube key={new Date().toString()} visible={this.state.modalVisible} onClose={() => this.setState({modalVisible: false})} />
      </div>
    )
  }
}
