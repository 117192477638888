import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ChocoBuy from 'assets/img/variants/choco-buy.jpg'
import OrangeBuy from 'assets/img/variants/orange-buy.jpg'
import { price_d2r, apiHelper, formVal, formScrollToError, dispatchMsg, formData } from 'helpers'
import { APISTAT, MODEL } from '_constant'
import Divider from '@material-ui/core/Divider';
import MyTextField from "components/general/MyTextField";
import ReactSelect from "components/general/ReactSelect";
import ShopeeIcon from 'assets/img/shopee.png'
import TokopediaIcon from 'assets/img/tokopedia.png'

export default class HomeForm extends Component {


  constructor(props) {
    super(props);
    this.state = {
      num_choco: 0,
      num_orange: 0,

      note: '',

      name: '',
      phone: '',
      email: '',
      id_province: 0,
      id_regency: 0,
      id_district: 0,
      ad_line: '',
      ad_postal: '',

      shipping_fee: 0,

      provinces: {},
      regencies: {},
      districts: {},

      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = event => {

    var name = event.target.name;
    var val = event.target.value;

    if (name === 'phone') {
      // filter phone and decide if we need to verify
      val = val.replace(/[^\d\s()+.-]/g, '');  // filter non number and (,),+,-
      this.setState({ [name]: val });
    } else if (name === 'id_regency') {
      this.setState({ [name]: val }, () => { this.loadDistrict(); this.loadShipping(); });
    } else if (name === 'id_province') {
      this.setState({ [name]: val }, this.loadRegency);
    } else if (name === 'num_choco' || name === 'num_orange') {
      this.setState({ [name]: val }, this.loadShipping); // load shipping fee
    } else {
      this.setState({ [name]: val });
    }
  };

  changeCounter = (label, isUp) => () => {
    let {num_choco, num_orange} = this.state;

    // use handleInputChange for change
    if(label === 'choco') {
      if(isUp)
      num_choco++;
      else
      num_choco--;

      this.handleInputChange({target: {name: 'num_choco', value: num_choco}});
    }
    if(label === 'orange') {
      if(isUp)
      num_orange++;
      else
      num_orange--;

      this.handleInputChange({target: {name: 'num_orange', value: num_orange}});
    }
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'hoyo_get', // use the secured one if loggedin
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, ...resp.data});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }

  loadDistrict = () => {
    apiHelper.request(this, {
      resource: 'districts_select',
      urlParams: [this.state.id_regency],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_district: '', districts: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadRegency = () => {
    apiHelper.request(this, {
      resource: 'regencies_select',
      urlParams: [this.state.id_province],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_regency: '', regencies: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadShipping = () => {

    let {num_choco, num_orange, id_regency} = this.state;

    let weight = (num_choco + num_orange) * 250; // consider 250 grams each
    if(id_regency === 0 || weight === 0) {
      this.setState({shipping_fee: 0}); // null first
      return;
    }

    let data = {
      id_regency_src: 181, // kota bandung, // provesty id
      id_regency_dst: id_regency,
      weight: weight,
      courier: 'jne'
    };

    apiHelper.request(this, {
      resource: 'shipping_fee',
      data: formData(data),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {

          // get the cheapest ones
          let costs = resp.data;
          let least_cost = Number.MAX_SAFE_INTEGER;
          for(let i=0; i<costs.length; i++) {
            let _c = costs[i].cost[0].value;
            if(_c < least_cost) {
              least_cost = _c;
            }
          }
          this.setState({shipping_fee: least_cost});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  validate = () => {

    this.errors = {};

    formVal(this, 'note', 'Catatan', '');
    formVal(this, 'name', 'Nama', 'required');
    formVal(this, 'email', 'Email', 'required|validEmail');
    formVal(this, 'phone', 'No. Whatsapp', 'required');
    formVal(this, 'id_province', 'Provinsi', 'required');
    formVal(this, 'id_regency', 'Kota/Kabupaten', 'required');
    formVal(this, 'ad_line', 'Alamat', 'required');

    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }


  render(){

    let {num_choco, num_orange, shipping_fee} = this.state;

    let total_choco = num_choco * 349000;
    let total_orange = num_orange * 349000;
    let subtotal = total_choco + total_orange;
    let total = subtotal + shipping_fee;


    return (
      <div style={{backgroundColor: '#F5F4E9', padding: '60px 0', marginTop: 75}}>

        <div className="container">
          <div className="centered" style={{fontWeight: 700, fontSize: 20, marginBottom: 24}}>Hōyō bisa kamu dapat di</div>


          <Grid container>
            <Grid item xs={12} sm={6}>
              <Button id="bt-shopee" onClick={(e) => { // add delay so the button animation is shown
                  e.preventDefault();
                  setTimeout(() => {
                    window.open('https://shopee.co.id/');
                  }, 300);
                }}
                fullWidth className="button-ecomm">
                <img src={ShopeeIcon} alt="shopee" />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button id="bt-toped" onClick={(e) => { // add delay so the button animation is shown
                  e.preventDefault();
                  setTimeout(() => {
                    window.open('https://www.tokopedia.com/');
                  }, 300);
                }}
                fullWidth className="button-ecomm">
                <img src={TokopediaIcon} alt="tokopedia" />
              </Button>
            </Grid>
          </Grid>

          <div className="centered" style={{fontWeight: 700, fontSize: 20, marginTop: 50, marginBottom: 24}}>atau bisa juga kamu pesan dengan mengisi form dibawah ini</div>

        </div>


        <div className="container" style={{backgroundColor: 'white',
          background: '#FFFFFF',
          border: '1px solid #DFDDCC',
          borderRadius: 10,
          marginTop: 20}} >

          <Grid container>
            <Grid item md={8} style={{padding: '4% 4%', borderRight: '1px solid #DFDDCC'}}>

              {/*Pesanan*/}
              <div style={{fontWeight: 600, fontSize: 18, marginBottom: 20}}>Pesanan</div>

              <Grid container direction="row" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6} container alignItems="center" >

                  <Grid item xs="auto" md="auto">
                    <img className="buy-img" src={ChocoBuy} style={{}} alt="choco"/>
                  </Grid>
                  <Grid item xs="auto" md="auto" style={{paddingLeft: 20}}>
                    <div className="buy-variant">{ MODEL.COKLAT }</div>
                    <div style={{display: 'inline-block', border: '1px solid #DFDDCC', borderRadius: 5}}>
                      <Grid container direction="row" alignItems="center" >
                        <Button className="buy-button-counter" onClick={this.changeCounter('choco', false)} disabled={num_choco === 0}>-</Button>
                        <div className="centered" style={{width: 40, backgroundColor: '#F5F4E9'}}>{num_choco}</div>
                        <Button className="buy-button-counter" onClick={this.changeCounter('choco', true)}>+</Button>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} container alignItems="center">
                  <Grid item xs="auto" md="auto">
                    <img className="buy-img" src={OrangeBuy} style={{}} alt="orange"/>
                  </Grid>
                  <Grid item xs="auto" md="auto" style={{paddingLeft: 20}}>
                    <div className="buy-variant">{ MODEL.ORANGE }</div>
                    <div style={{display: 'inline-block', border: '1px solid #DFDDCC', borderRadius: 5}}>
                      <Grid container direction="row" alignItems="center" >
                        <Button className="buy-button-counter" onClick={this.changeCounter('orange', false)} disabled={num_orange === 0}>-</Button>
                        <div className="centered" style={{width: 40, backgroundColor: '#F5F4E9'}}>{num_orange}</div>
                        <Button className="buy-button-counter" onClick={this.changeCounter('orange', true)}>+</Button>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>


              <MyTextField name="note" style={{marginTop: 10}} label="Catatan" placeholder="Contoh: Ukuran L (1 buah), M (2 buah)" obj={this} required variant="outlined"
                />

              <div style={{fontWeight: 600, fontSize: 18, marginBottom: 10, marginTop: 20}}>Informasi Personal</div>

              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="buy-field">
                    <MyTextField name="name" label="Nama" placeholder="Contoh: Ukuran L (1 buah), M (2 buah)" obj={this} required variant="outlined"
                      />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="buy-field">
                    <MyTextField name="phone" label="No. Whatsapp" obj={this} required variant="outlined"
                      />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="buy-field">
                    <MyTextField name="email" label="Email" obj={this} required variant="outlined"
                      />
                  </div>
                </Grid>
              </Grid>

              <div style={{fontWeight: 600, fontSize: 18, marginBottom: 10, marginTop: 20}}>Alamat Pengiriman</div>

              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="buy-field-adr">
                    <ReactSelect name="id_province" label="Provinsi" obj={this}
                      options={Object.keys(this.state.provinces).map(k => ({k: k, v:this.state.provinces[k]}))}
                      required variant="outlined" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="buy-field-adr">
                    <MyTextField name="ad_postal" label="Kode Pos" obj={this}
                      required variant="outlined" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="buy-field-adr">
                    <ReactSelect name="id_regency" label="Kota/Kabupaten" obj={this}
                      options={Object.keys(this.state.regencies).map(k => ({k: k, v:this.state.regencies[k]}))} noOption="Pilih Provinsi terlebih dahulu"
                      required variant="outlined" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="buy-field-adr">
                    <ReactSelect name="id_district" label="Kecamatan" obj={this}
                      options={Object.keys(this.state.districts).map(k => ({k: k, v:this.state.districts[k]}))} noOption="Pilih Kota/Kabupaten terlebih dahulu"
                      required variant="outlined" />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="buy-field-adr">
                    <MyTextField name="ad_line" label="Perumahan/Nama Jalan/No. Rumah" obj={this}
                      required variant="outlined" />
                  </div>
                </Grid>

              </Grid>


            </Grid>

            <Grid item xs={12} md={4} style={{padding: '4% 4%'}}>
              <div style={{fontWeight: 600, fontSize: 18, marginBottom: 38}}>Rangkuman</div>

              <div>
                <Grid container className="summary-line" direction="row">
                  <Grid item>{ MODEL.COKLAT } (x{num_choco})</Grid>
                  <Grid item xs></Grid>
                  <Grid item>{price_d2r(total_choco)}</Grid>
                </Grid>
              </div>
              <div>
                <Grid container className="summary-line" direction="row">
                  <Grid item>{ MODEL.ORANGE } (x{num_orange})</Grid>
                  <Grid item xs></Grid>
                  <Grid item>{price_d2r(total_orange)}</Grid>
                </Grid>
              </div>
              <Divider style={{marginTop: 8}}/>

              <div>
                <Grid container className="summary-line" direction="row">
                  <Grid item>Subtotal</Grid>
                  <Grid item xs></Grid>
                  <Grid item>{price_d2r(subtotal)}</Grid>
                </Grid>
              </div>
              <div>
                <Grid container className="summary-line" direction="row">
                  <Grid item>Ongkir</Grid>
                  <Grid item xs></Grid>
                  <Grid item>{price_d2r(shipping_fee)}</Grid>
                </Grid>
              </div>

              <Divider style={{marginTop: 8}}/>
              <div>
                <Grid container className="summary-line" alignItems="center" direction="row">
                  <Grid item>Total</Grid>
                  <Grid item xs></Grid>
                  <Grid item style={{fontSize: 16}}>{price_d2r(total)}</Grid>
                </Grid>
              </div>

              <Button className="button-orange" style={{marginTop: 30, paddingTop: 13, paddingBottom: 13, fontSize: 16, fontWeight: 600}} fullWidth>Buat Pemesanan</Button>

            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
