import React, { Component } from 'react';
// import Footer from 'components/general/Footer';
import HomeBanner from 'components/home/HomeBanner'
import HomeOption from 'components/home/HomeOption'
import HomeTestimonial from 'components/home/HomeTestimonial'
import HomeAbout from 'components/home/HomeAbout'
import HomeWhyHoyo from 'components/home/HomeWhyHoyo'
import HomeForm from 'components/home/HomeForm'

import Footer from 'components/general/Footer'
import { Element } from 'react-scroll'
import WhatsappFloating from 'components/general/WhatsappFloating'

class Home extends Component {

  componentDidMount() {
  }

  render(){

    return(
      <div className="detail-product-width">
        <div className="nav-home">
          <HomeBanner />
          <Element name="produk">
            <HomeOption />
          </Element>
          <HomeTestimonial />
          <Element name="tentang">
            <HomeAbout />
          </Element>
          <Element name="why">
            <HomeWhyHoyo />
          </Element>
          <Element name="order">
            <HomeForm />
          </Element>
          {/*<Navbar history={this.props.history} />*/}

          <div className="home2-padding" />
        </div>

        <WhatsappFloating />

        <Footer />
      </div>
    )
  }
}

export default Home
