import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

const SocialMedia = props => (
  <div>
    <Grid container>
      <Button href="https://www.instagram.com/babyhoyo/" target="_blank" className="btn-socmed">
        <InstagramIcon />
      </Button>

      {/*<a href="https://www.facebook.com/provestyid/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', outline: 'none'}}>
      <button className="fa fa-facebook fa-socmed"><span></span></button>
      </a>
      <a href="https://twitter.com/provestyid" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', outline: 'none'}}>
      <button className="fa fa-twitter fa-socmed"><span></span></button>
      </a>*/}

      <Button href="https://www.youtube.com/channel/UCt9iwWtQupDYxObZdZ_QSxw" target="_blank" className="btn-socmed">
        <YouTubeIcon />
      </Button>


    </Grid>
  </div>)


  export default SocialMedia;
