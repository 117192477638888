import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Logo_white from "assets/img/logo-white-small.png";
import Logo from "assets/img/logo-small.png";
import Sticky from 'react-sticky-el';
import { Link } from "react-router-dom";
import {Link as Scroller} from 'react-scroll'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import {debounceFn} from 'helpers';
import MenuLogo from "assets/img/menu.png";
// import LangToggle from './LanguageToggle';

// Exposes the injected properties. This is to wrap hidden and NavLink wrapped menu
// const Wire = ({ children, ...props }) => children(props);

class Navbar extends Component {
  constructor(props){
    super(props);
    this.state = {
      isTopWindow: true,

      // popper
      extOtherOpen: false,
      extHiwOpen: false,
      extUserOpen: false,

      // drawer
      isDrawerOpen: false
    };
  }

  handlePopperToggle = section => () => {
    var name = 'ext' + section + 'Open';
    this.setState({ [name]: !this.state[name] });
  };

  handlePopperClose = section => event => {
    var name = 'ext' + section + 'Open';
    this.setState({ [name]: false });
  };

  handleScroll = () => {
    //
    // // const isTopWindow = window.scrollY <= 600;
    // const isTopWindow = (this.state.isTopWindow && window.scrollY <= 150) || (!this.state.isTopWindow && window.scrollY <= 100) ;
    //
    // // don't switch if we are in transition
    // if (isTopWindow !== this.state.isTopWindow) {
    //   this.handlePopperClose('Other')();
    //   this.handlePopperClose('Hiw')();
    //   this.handlePopperClose('User')();
    //   this.setState({ isTopWindow });
    // }
  }

  openDrawer = (open) => () => {
    this.setState({isDrawerOpen: open});
  }

  componentDidMount(){
    this.debounceCallScroll = debounceFn(this.handleScroll, 350);
    document.addEventListener('scroll', this.handleScroll);
    window.onresize = this.toggleTawkTo;
  }

  componentDidUpdate(prevProps, prevState) {
    this.toggleTawkTo();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  // use this to toggle tawkTo, consider it is shown first
  tawkToShown = true;
  toggleTawkTo = () => {
    var _showTalkTo = true;
    // only matters in mobile AND when TawkTo is present
    if(window.screen.width < 600) {
      var isDashboardPage = false
      _showTalkTo = !isDashboardPage;
    }

    if((this.tawkToShown && !_showTalkTo) ||  (!this.tawkToShown && _showTalkTo)) {
      var t_api = typeof Tawk_API !== 'undefined'? Tawk_API: null; // eslint-disable-line no-undef
      if(t_api && typeof t_api.showWidget !== 'undefined' && typeof t_api.hideWidget !== 'undefined') {
        if(_showTalkTo) {
          t_api.showWidget();
        } else {
          t_api.hideWidget();
        }
        // only change when it is confirmed shown/hidden
        if((_showTalkTo && !t_api.isChatHidden()) || (!_showTalkTo && t_api.isChatHidden()))
        this.tawkToShown = _showTalkTo;
      }
    }
  }


  render() {
    // return (<div className="navbar"><div className="sub-navbar"></div></div>);
    const { isTopWindow, extUserOpen} = this.state;

    const navbarClass = 'navbar ' + (isTopWindow? "" : "navbar-fixed scale-in-ver-top");
    const LogoChange = isTopWindow ? Logo : Logo_white;
    const subNavbar = "sub-navbar container";
    const btnNavbar = "button-navbar"

    const btnMobileMenu = "button-hamburger " + (isTopWindow ? "button-menu":"button-menu-bottom");

    var mobileDrawer =
    <Drawer
      anchor="right"
      open={this.state.isDrawerOpen}
      onClose={this.openDrawer(false)}
      >
      <div
        tabIndex={0}
        role="button"
        onClick={this.openDrawer(false)}
        onKeyDown={this.openDrawer(false)}
        >
        <div id="mobile-drawer">
          <div id="mobile-menu">
            <div className="sub-menu-mobile">Menu</div>
            <List>
              {
                [
                  {path: "produk", label: 'Produk', icon: <Icon>category</Icon>},
                  {path: "tentang", label: 'Tentang Hōyō', icon: <Icon>child_friendly</Icon>},
                  {path: "order", label: 'Pemesanan', icon: <Icon>shopping_cart</Icon>}
                ].map(x => {
                  return (
                    <Scroller key={x.path} to={x.path} onClick={this.openDrawer(false)} smooth={true} duration={800}>

                      <ListItem button classes={{root: 'list-root'}}>
                        <ListItemIcon style={{minWidth: 0, marginRight: 16}}>{x.icon}</ListItemIcon>
                        <ListItemText primary={x.label} classes={{primary: 'list', root:'list-item-root'}} />
                      </ListItem>
                    </Scroller>
                  )}
                )
              }
            </List>
          </div>
        </div>
      </div>
    </Drawer>;

    const navbar =
    <div className={navbarClass}>
      <div className={subNavbar}>
        <Grid container alignItems="center">
          <Grid item xs="auto">
            <Link id="link-logo" to="/"><img className="img-logo" src={LogoChange} alt="provesty_logo" /></Link>
          </Grid>
          <Hidden smDown>
            <Grid item md id="navbar-menu-container">
              <Grid container>
                {
                  [
                    {path: "produk", label: 'Produk'},
                    {path: "tentang", label: 'Tentang Hōyō'},
                    {path: "order", label: 'Pemesanan'},
                  ].map(x => {
                    return (
                      <Scroller key={x.path} to={x.path} smooth={true} duration={800}>
                        <Button className={btnNavbar}>{x.label}</Button>
                      </Scroller>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Hidden>
          {/*mobile toggle*/}
          <Hidden mdUp>
            <Grid item xs={true}>
              <div className="menu-position">
                <Button buttonRef={node => {
                    this.anchorUserEl = node;
                  }}
                  aria-owns={extUserOpen ? 'menu-list-grow-user' : undefined}
                  aria-haspopup="true"
                  className={btnMobileMenu}
                  onClick={this.handlePopperToggle('User')}
                  style={{minWidth: 0}}>
                  <img src={MenuLogo} alt="menu"></img>
                </Button>
                <Popper open={extUserOpen} anchorEl={this.anchorUserEl} transition placement="bottom-end" disablePortal={true}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow-user"
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                      <Paper>
                        <ClickAwayListener onClickAway={this.handlePopperClose('User')}>
                          <MenuList className="menu-other">
                            {
                              [
                                {path: "produk", label: 'Produk'},
                                {path: "tentang", label: 'Tentang Hōyō'},
                                {path: "order", label: 'Pemesanan'},
                              ].map(x =>
                                <Scroller key={x.path} to={x.path} smooth={true} duration={800}>
                                  <MenuItem className={btnNavbar} onClick={this.handlePopperClose('User')}>{x.label}</MenuItem>
                                </Scroller>
                              )
                            }
                          </MenuList>

                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Grid>
            {mobileDrawer}
          </Hidden>
        </Grid>
      </div>
    </div>;

    const Sticked = isTopWindow ? <div>{navbar}</div> : <Sticky>{navbar}</Sticky>;

    return (
      <React.Fragment>
        <div style={{zIndex: 101, position:'relative'}}>
          {Sticked}
        </div>
      </React.Fragment>
    );
  }

}

export default Navbar;
