import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Slider from "react-slick";
import Fab from '@material-ui/core/Fab';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Hidden from '@material-ui/core/Hidden';
import Tirta from 'assets/img/testi/tirta.png'
import Sabai from 'assets/img/testi/sabai.jpg'
import Afifah from 'assets/img/testi/afifah.jpg'
import Frescil from 'assets/img/testi/frescil.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class HomeTestimonial extends Component {

  next = () => {
    this.slider.slickNext();
  }
  previous = () => {
    this.slider.slickPrev();
  }

  render(){

    const lists = [
      {
        name: 'Dr. Tirta Anugrah', job: 'Spesialis Laktasi', img: Tirta,
        testi: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum adipiscing tellus turpis massa ullamcorper ac ipsum eget. Sapien sem diam vitae'
      },
      {
        name: 'Sabai Morscheck', job: 'Artis', img: Sabai,
        testi: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum adipiscing tellus turpis massa ullamcorper ac ipsum eget. Sapien sem diam vitae'
      },
      {
        name: 'Dr. Frescil', job: 'Dokter Spesialis Anak', img: Frescil,
        testi: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum adipiscing tellus turpis massa ullamcorper ac ipsum eget. Sapien sem diam vitae'
      },
      {
        name: 'Afifah Mu\'minah', job: 'Konsultan Gendong', img: Afifah,
        testi: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum adipiscing tellus turpis massa ullamcorper ac ipsum eget. Sapien sem diam vitae'
      },
    ];

    return (
      <div className="container" id="container-testi">
        <Grid container direction="row" alignItems="center">
          <Hidden smDown>
            <Grid item lg={1} md={1} sm={false} xs={false}>
              <Fab raised="true" onClick={this.previous} style={{backgroundColor: 'white', borderRadius: '50%'}}>
                <ChevronLeft />
              </Fab>
            </Grid>
          </Hidden>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Slider ref={c => (this.slider = c)} {...sliderSetting}>
              {
                lists.map((v, index) => {
                  return(
                    <div key={index} >
                      <div className="testi-slot">
                        <Card className="home-card" raised style={{borderRadius: 10}}>
                          <div style={{padding: 10}}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm="auto" className="testi-photo">
                                <CardMedia image={v.img}
                                  style={{
                                    borderRadius: 10,
                                  }}
                                  />
                              </Grid>
                              <Grid item xs>
                                <Grid container direction="column" className="testi-content">
                                  <Grid item>
                                    {v.testi}
                                  </Grid>
                                  <Grid item container style={{marginTop: 15}}>
                                    <Grid item>
                                      <div style={{width: 36, border: "1px solid #314A37", marginTop: 15, marginRight: 15}} />
                                    </Grid>
                                    <Grid item>
                                      <div style={{fontSize: 20, fontWeight: 700}}>{v.name}</div>
                                      <div>{v.job}</div>
                                    </Grid>
                                  </Grid>
                                </Grid>

                              </Grid>
                            </Grid>
                          </div>
                        </Card>
                      </div>
                    </div>
                  )
                })
              }
            </Slider>
          </Grid>
          <Hidden smDown>
            <Grid item lg={1} md={1} sm={false} xs={false} style={{textAlign: 'right'}}>
              <Fab raised="true" onClick={this.next} style={{backgroundColor: 'white', borderRadius: '50%'}}>
                <ChevronRight />
              </Fab>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    )
  }
}

const sliderSetting = {
  className: 'center',
  centerMode: true,
  infinite: true,
  centerPadding: '0px',
  variableWidth: true,
  // slidesToShow: 3,
  speed: 300,
  arrows: false,
  dots: false,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        centerMode: false,
        dots: true,

      }
    },
    {
      breakpoint: 599,
      settings: {
        centerMode: false,
        dots: true,

      }
    },
    // {
    //   breakpoint: 350,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     initialSlide: 1,
    //     speed: 150,
    //     dots: true,
    //     centerMode: true,
    //     variableWidth: true
    //   }
    // }
  ]
};
