import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TogetherImg from 'assets/img/together.jpg'
import { Link } from "react-router-dom";
import ModalStory from 'components/home/ModalStory'

export default class HomeAbout extends Component {

  constructor(props) {
    super(props);
    this.state = {

      modalVisible: false,
    };
  }

  render(){

    return (
      <div style={{marginTop: 120}}>
        <div style={{backgroundColor: '#314A37'}}>
          <div className="container">
            <Grid container>
              <Hidden smDown>
                <Grid item xs={4} md={4} lg={5} container alignItems="center">
                  <img src={TogetherImg} alt="contoh" style={{width: '100%', display: 'block'}}></img>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={8} lg={7} className="about-content">
                <div className="color-light" id="hoyo-about-title" style={{fontWeight: 700}}>Hōyō Babywearing Shirt</div>

                <div className="color-light" id="hoyo-about-desc" style={{fontWeight: 500}}>
                  <p>
                    Hōyō adalah sebuah produk baju gendongan pertama di Indonesia. Baju ini memiliki 3 layer di bagian depan untuk menopang tubuh bayi dengan posisi optimal dan ergonomis. Dua layer bagian dalam stretch secara horizontal agar bayi lebih nyaman, dan dapat dijadikan penopang kepala. Sementara layer terluar stretch secara vertikal agar bayi mudah untuk masuk dan keluar gendongan, serta berfungsi untuk menopang punggung bayi.
                  </p>
                  <Hidden mdUp>
                    <img src={TogetherImg} alt="contoh" style={{width: '100%', display: 'block'}}></img>
                  </Hidden>
                  <p>
                    Kami menggunakan bahan tencel yang sifatnya melar ke satu arah namun tetap kokoh, sehingga anak merasakan kenyamanan lebih saat digendong.
                    Bahan tencel ini terbuat dari berasal dari alam, memiliki sifat anti bakteri, nyaman di kulit, kelembutan tahan lama, sehalus sutra, peningkatan daya bernapas, ketahanan warna, dan daya urai.
                  </p>
                </div>
              </Grid>
            </Grid>

          </div>
        </div>

        <div style={{textAlign: 'center', position: 'relative', marginTop: -40, maxWidth: '95%', marginLeft: 'auto', marginRight: 'auto'}}>
          <div style={{
              padding: '25px 63px', backgroundColor: '#F5F4E9',
              boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
              borderRadius: 10,
              fontWeight: 600,
              fontSize: 18,
              display: 'inline-block'
            }}>Psst... Ada ceritanya loh dibalik Hōyō, mau tahu? <Link to="" onClick={() => this.setState({modalVisible: true})} style={{color: '#B25416'}}>Baca ceritanya</Link>
          </div>
        </div>
        <ModalStory key={1} visible={this.state.modalVisible} onClose={() => this.setState({modalVisible: false})} />
      </div>

    );
  }
}
