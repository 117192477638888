import React from 'react';
import Rodal from 'rodal';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import "react-image-gallery/styles/css/image-gallery.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Sarah1 from 'assets/img/sarah1.jpg';
import Sarah2 from 'assets/img/sarah2.jpg';
import Slider from "react-slick";

export default class ModalStory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentIdx: 0
    };
  }


  beforeChange = (oldIndex, newIndex) => {
    this.setState({currentIdx: newIndex});
  }

  next = ()  => {
    this.slider.slickNext();
  }
  previous = () => {
    this.slider.slickPrev();
  }

  render() {

    const { visible, onClose } = this.props;
    const { currentIdx } = this.state;

    const slideSettings = {
      dots: false,
      infinite: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.beforeChange
    };

    return (

      <Rodal className="youtube-modal" visible={visible} onClose={onClose}
        showCloseButton={false} width={750} customStyles={{ height: 'auto', bottom: 'auto', top: '50%', transform: 'translateY(-50%)'}}
        >
        <div className="sms-modal-content" ref={ (divElement) => this.divElement = divElement}>
          <Button onClick={onClose} className="rodal-btn-close"><CloseIcon /></Button>
          <Grid container direction="row" style={{padding: 20}} spacing={3}>
            <Hidden xsDown>
              <Grid item xs={12} sm={4} style={{position: 'relative'}}>
                <TransitionGroup style={{position: 'relative'}}>
                  <CSSTransition
                    key={'image' + currentIdx}
                    timeout={{ enter: 500, exit: 500 }}
                    classNames={'fade500'}
                    >
                    <img className="photo-story" src={currentIdx === 0 ? Sarah1 : Sarah2 } alt="Sarah" />
                  </CSSTransition>
                </TransitionGroup>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={8} container direction="column" className="color-theme">
              <Grid item style={{fontSize: 20, fontWeight: 700}}>Story Behind</Grid>
              <Grid item xs style={{fontWeight: 500, fontSize: 14}}>

                <Slider ref={c => (this.slider = c)} {...slideSettings}>

                  <div>
                    <p>
                      Hai, saya Sarah :)
                    </p>
                    <p>
                      Berawal dari tahun 2017 ketika aku melahirkan anak pertama, anakku (Aiqa) mengalami jaundice. Bilirubinnya tinggi di hari keempat setelah kelahirannya. Kami konsultasi ke dokter dan kami mengatasi kondisi tersebut dengan gempur ASI dan fototerapi. Selama beberapa waktu yang terasa lama, kami struggling dalam kondisi ini. Memang melelahkan, namun Alhamdulillaah akhirnya jaundice berlalu juga..
                    </p>
                  </div>
                  <div>
                    <p>
                      Tidak berhenti disitu, beberapa bulan setelahnya, Aiqa kembali sakit. Ia demam setelah imunisasi DPT dan berlanjut terkena DBD. Hampir setiap malam demamnya mencapai 40&#8451;. Salah satu upaya kami agar Aiqa cepat sembuh adalah melakukan skin to skin.
                    </p>
                    <p>
                      Dari sinilah saya mulai terpikirkan untuk membuat baju gendongan yang sekarang kita kenal sebagai hōyō. Produk ini sudah melalui Research and Development yang panjang, didampingi oleh Teh Afifah Mu’minah selaku Certified Babywearing Consultant.
                    </p>
                  </div>
                </Slider>
              </Grid>
              <Grid item container direction="row">
                <Button className="button-black-trans" style={{fontWeight: 500, fontSize: 14, minWidth: 90, marginRight: 10}} onClick={this.previous} disabled={currentIdx===0}>Back</Button>
                <Button className="button-black-trans" style={{fontWeight: 500, fontSize: 14, minWidth: 90}} onClick={this.next} disabled={currentIdx===1}>Next</Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Rodal>
    );
  }
}
