import React from 'react';
import Rodal from 'rodal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CloseIcon from '@material-ui/icons/Close';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

export default class ModalShowcase extends React.Component {

  render() {

    const { visible, onClose, data } = this.props;

    var imgs =  data.photos.map(img => ({
      original:  img,
      thumbnail: img,
      sizes: "600x450"
    }));

    return (
      <Rodal className="youtube-modal" visible={visible} onClose={onClose}
        showCloseButton={false} width={700} customStyles={{ height: 'auto', bottom: 'auto', top: '50%', transform: 'translateY(-50%)'}}
        >
        <div className="sms-modal-content" ref={ (divElement) => this.divElement = divElement}>
          <Button onClick={onClose} className="rodal-btn-close"><CloseIcon /></Button>
          <Grid container direction="row" alignItems="center" >
            <Grid item xs={12} sm={6} style={{padding: 20}}>
              <ImageGallery
                infinite={true}
                showBullets={true}
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails={true}
                showIndex={false}
                disableArrowKeys={true}
                autoPlay={false}
                items={imgs}
                slideDuration={250}
                renderLeftNav={(onClick, disabled) =>
                  <Button
                    className='image-gallery-nav'
                    style={{left: 9}}
                    disabled={disabled}
                    onClick={onClick}>
                    <ChevronLeft />
                  </Button>
                }
                renderRightNav={(onClick, disabled) =>
                  <Button
                    className='image-gallery-nav'
                    style={{right: 9}}
                    disabled={disabled}
                    onClick={onClick}>
                    <ChevronRight />
                  </Button>
                }
                />
            </Grid>
            <Grid item xs={12} sm={6} style={{padding: '0 20px 20px'}}>
              <div style={{marginTop: 5, fontSize: 20, fontWeight: 700}}>{data.label}</div>
              <div style={{marginTop: 10, fontSize: 14, fontWeight: 500}}>{data.desc}</div>

              <div style={{marginTop: 25, fontSize: 14, fontWeight: 700}}>Ukuran yang Tersedia</div>
              <div style={{marginTop: 10, fontSize: 18, fontWeight: 500}}>{
                  Object.keys(data.sizes).map((k, index) => <div className="list-size" key={index} style={{backgroundColor: data.sizes[k] === 0? '#D6D6D6' : '#FFFFFF' }}>{k}</div>)
                }
              </div>
              <Hidden xsDown>
                <Button className="button-orange" style={{paddingTop: 11, paddingBottom: 11, fontSize: 16, fontWeight: 600, marginTop: 25}}>Beli Sekarang</Button>
              </Hidden>
            </Grid>
          </Grid>
        </div>
      </Rodal>
    );
  }
}
