import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

export default class WhatsappFloating extends Component {

  render(){

    const link = "https://api.whatsapp.com/send?phone=6285722202275&text=halo!%20saya%20tertarik%20untuk%20tau%20lebih%20jauh%20tentang%20baju%20gendongan%20h%C5%8Dy%C5%8D%20%E2%98%BA%EF%B8%8F";

    return (
      <div id="button-wa" style={{position: 'fixed', bottom: 18}}>
        <Button style={{
            background: '#314A37',
            borderRadius: '50%',
            width: 64,
            height: 64,
            boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)'
          }}
          target="_blank"
          onClick={(e) => { // add delay so the button animation is shown
            e.preventDefault();
            setTimeout(() => {
              window.open(link);
            }, 300);
          }
        }
        >
        <WhatsAppIcon style={{color: 'white', fontSize: 30}} />
      </Button>
    </div>
  )
}
}
