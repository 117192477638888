import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Choco from 'assets/img/variants/choco.jpg'
import Orange from 'assets/img/variants/orange.jpg'
import Or1 from 'assets/img/variants/or1.jpg';
import Or2 from 'assets/img/variants/or2.jpg';
import Or3 from 'assets/img/variants/or3.jpg';
import Or4 from 'assets/img/variants/or4.jpg';
import Ch1 from 'assets/img/variants/ch1.jpg';
import Ch2 from 'assets/img/variants/ch2.jpg';
import Ch3 from 'assets/img/variants/ch3.jpg';
import Ch4 from 'assets/img/variants/ch4.jpg';
import ModalShowcase from 'components/home/ModalShowcase'
import { MODEL } from '_constant'

export default class HomeOption extends Component {


  constructor(props) {
    super(props);
    this.state = {

      modalVisible: false,
      indexChosen: 0

    };
  }

  openModal = (idx) => () => {
    this.setState({indexChosen: idx, modalVisible: true});
  }


  render(){
    let variants = [
      {
        label: MODEL.COKLAT, img: Choco, photos: [Ch1, Ch2, Ch3, Ch4], desc: 'Memberikan kesan stabil, sejuk, dan kuat untuk mensupport bayi', sizes: {'S': 1, 'M': 1, 'L': 1, 'XL' : 1}
      },
      {
        label: MODEL.ORANGE, img: Orange, photos: [Or1, Or2, Or3, Or4], desc: 'Memberikan kesan hangat, ceria, dan menstimulasi', sizes: {'S': 1, 'M': 1, 'L': 1, 'XL' : 1}
      }
    ];


    return (
      <div className="container" style={{paddingTop: 70}}>

        <div className="centered fontsize-medbig" style={{fontWeight: 600}}>
          Tersedia dalam 2 pilihan
        </div>

        <Grid container justify="center" className="mobile-grid-container" spacing={8} style={{marginTop: 21}}>
          {

            variants.map((v, index) =>
            <Grid item key={index}>

              <Card className="card-option" raised style={{borderRadius: 10}}>
                <CardMedia image={v.img} title="product-invest"
                  style={{
                    width: 340,
                    height: 340,
                    maxWidth: '100%',
                    position: 'relative'
                  }} >

                  <div style={{background: 'linear-gradient(360deg, rgba(19, 28, 21, 0.75) 0%, rgba(55, 47, 42, 0) 100%)',
                    width: '100%',
                    height: 162,
                    position: 'absolute',
                    bottom: 0}}>

                  </div>
                  <div className="centered option-label">
                    <div style={{display: 'inline-block'}}>{v.label}</div><Button className="button-white-trans btn-see-variant" onClick={this.openModal(index)}>Lihat</Button>
                  </div>
                </CardMedia>
              </Card>

            </Grid>
          )
        }
      </Grid>


      <ModalShowcase key={this.state.indexChosen} data={variants[this.state.indexChosen]} visible={this.state.modalVisible} onClose={() => this.setState({modalVisible: false})} />

    </div>
  )
}
}
